import { createBrowserRouter, Navigate } from 'react-router-dom';

import RootView from '@/views/RootView';
import ProtectedRoute from '@/components/utility/ProtectedRoute';

// auth route views
import AuthView from '@/views/auth';
import SignInView from '@/views/auth/SignIn';
import ForgotPasswordView from '@/views/auth/ForgotPassword';
import ResetPasswordEmail from '@/views/auth/ResetPasswordEmail';
import ResetPasswordView from '@/views/auth/ResetPassword';
import ResetPasswordSuccess from '@/views/auth/ResetPasswordSuccess';

import HomeView from '@/views/home';

// admin views
import AdminView from 'src/views/home/admin';

// admin customer views
import CustomersView from '@/views/home/customer';
import NewCustomerView from '@/views/home/customer/NewCustomer';

import { Routes } from '@/constants/routes';
import { getPath } from '@/utils';
import PermissionAwareNavigate from '@/components/utility/PermissionAwareNavigate';

const router = createBrowserRouter([
  {
    path: Routes.Root,
    element: <RootView />,
    children: [
      {
        index: true,
        element: <Navigate replace to={Routes.Home} />,
      },
      {
        path: Routes.Home,
        element: (
          <ProtectedRoute>
            <HomeView />
          </ProtectedRoute>
        ),
        children: [
          {
            index: true,
            element: (
              <PermissionAwareNavigate
                to={[
                  [['users', 'customers'], Routes.Admin],
                  [['customers'], Routes.Customers],
                ]}
                replace
              />
            ),
          },
          {
            path: Routes.Admin,
            element: <AdminView />,
          },
          {
            path: Routes.Customers,
            element: <CustomersView />,
          },
        ],
      },
      {
        path: getPath(Routes.Home, Routes.Customers, Routes.NewCustomer),
        element: (
          <ProtectedRoute>
            <NewCustomerView />
          </ProtectedRoute>
        ),
      },
      {
        path: getPath(Routes.Home, Routes.Customers, Routes.CustomerDetails),
        element: (
          <ProtectedRoute>
            <NewCustomerView />
          </ProtectedRoute>
        ),
      },
      {
        path: Routes.Auth,
        element: (
          <ProtectedRoute authFlow>
            <AuthView />
          </ProtectedRoute>
        ),
        children: [
          {
            index: true,
            element: <Navigate replace to={Routes.SignIn} />,
          },
          {
            path: Routes.SignIn,
            element: <SignInView />,
          },
          {
            path: Routes.ForgotPassword,
            element: <ForgotPasswordView />,
          },
          {
            path: `${Routes.PasswordReset}/:token'`,
            element: <ResetPasswordView />,
          },
        ],
      },
      {
        path: getPath(Routes.Auth, Routes.ResetPasswordEmail),
        element: <ResetPasswordEmail />,
      },
      {
        path: getPath(Routes.Auth, Routes.ResetPasswordSuccess),
        element: <ResetPasswordSuccess />,
      },
    ],
  },
]);

export default router;
