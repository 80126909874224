import { SXStyleSheet } from '@/utils';
import { iconButtonClasses } from '@mui/material';

const style = SXStyleSheet.create({
  icon: {},
  outlined: {
    width: '40px',
    height: '40px',
    borderRadius: 2,
    border: '1px solid #EBEDF1',
    [`&.${iconButtonClasses.disabled}`]: {
      opacity: 0.3,
    },
  },
  light: {
    color: 'primary.contrastText',
  },
  primary: {
    color: 'primary.main',
  },
});

export default style;
