import { SXStyleSheet } from '@/utils';

const style = SXStyleSheet.create({
  container: {
    direction: 'row',
    position: 'relative',
    overflow: 'hidden',
    flex: 1,
    width: '100%',
    height: '100%',
  },
  stepContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    overflow: 'auto',
    display: 'none',
    backgroundColor: 'background.card',
    borderRadius: 3,
  },
});

export default style;
