import { forwardRef, useImperativeHandle, useMemo, useState } from 'react';
import { CustomerSteps } from '@/constants/steppers';
import Stepper from '@/components/ui/Stepper';
import { findIndex } from 'lodash';
import ChildrenForm from '@/components/forms/ChildrenForm';
import { useTranslation } from 'react-i18next';

export interface ChildrenStepperProps {
  onStepperStart: () => void;
  onFinishForm: () => void;
  onCancel: () => void;
  formTouched?: boolean;
  hasError?: boolean;
}

export interface ChildrenStepperRef {
  prevStep: () => void;
}

const Steps = {
  [CustomerSteps.Other]: <ChildrenForm formKey="customer" />,
};

const ChildrenStepper = forwardRef<ChildrenStepperRef, ChildrenStepperProps>(
  ({ onStepperStart, onFinishForm, onCancel, formTouched }, ref) => {
    const { t } = useTranslation();

    const [activeStep, setActiveStep] = useState(CustomerSteps.Other);

    const childrenSteps = useMemo(() => {
      return [
        {
          id: CustomerSteps.Other,
          title: t('customer:newCustomer.steps.children'),
          disabled: true,
        },
      ];
    }, [t]);

    const prevStep = () => {
      const prevStepIndex = findIndex(childrenSteps, { id: activeStep }) - 1;

      if (prevStepIndex < 0) {
        onStepperStart();
      } else {
        setActiveStep(childrenSteps[prevStepIndex].id as CustomerSteps);
      }
    };

    useImperativeHandle(ref, () => ({
      prevStep,
    }));

    return (
      <Stepper
        active={activeStep}
        stepMap={Steps}
        steps={childrenSteps}
        nextLabel={t('customer:newCustomer.save')}
        savedLabel={t('customer:newCustomer.saved')}
        cancelLabel={t('customer:newCustomer.cancel')}
        errorLabel={t('customer:newCustomer.error')}
        onCancel={onCancel}
        onNext={onFinishForm}
        formTouched={formTouched}
      />
    );
  },
);

export default ChildrenStepper;
