import { SXStyleSheet } from '@/utils';

const style = SXStyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: 'grey.A100',
    flex: 1,
    justifyContent: 'center',
    height: 32,
    borderRadius: 1,
    position: 'relative',
    color: 'primary.contrastText',
  },
  containerActive: (theme) => ({
    backgroundColor: 'primary.altLight',
    '& .step-arrow-right': {
      borderLeftColor: theme.palette.primary.altLight,
    },
    '& .step-title': {
      color: 'primary.contrastText',
    },
  }),
  containerDone: (theme) => ({
    backgroundColor: 'primary.altLighter',
    '& .step-arrow-right': {
      borderLeftColor: theme.palette.primary.altLighter,
    },
    '& .step-title': {
      color: 'primary.altLight',
    },
  }),
  containerLastItem: {
    borderRadius: 1,
  },
  arrow: {
    width: 0,
    height: 0,
    position: 'absolute',
  },
  arrowLeft: (theme) => ({
    left: 0,
    borderTop: '14px solid transparent',
    borderBottom: '14px solid transparent',
    borderLeft: `28px solid ${theme.palette.background.card}`,
  }),
  arrowRight: (theme) => ({
    right: -30,
    zIndex: 1,
    borderTop: '16px solid transparent',
    borderBottom: '16px solid transparent',
    borderLeft: `32px solid ${theme.palette.grey.A100}`,
  }),
  nextStep: {
    opacity: 0,
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export default style;
