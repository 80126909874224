import React, { FC } from 'react';
import { map } from 'lodash';
import {
  ListSubheader,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';

import style from './style';
import { isSelectGroup } from '@/utils';

export interface SingleSelectItem {
  value: string;
  label: string;
}

export interface SingleSelectGroupItem {
  label: string;
  items: SingleSelectItem[];
}

export interface SingleSelectProps {
  value: string;
  labelFixedWidth?: string;
  items: (SingleSelectItem | SingleSelectGroupItem)[];
  label?: string;
  subtitle?: string;
  error?: string | null;
  onSelect?: (value: string) => void;
}

const SingleSelect: FC<SingleSelectProps> = ({
  items,
  value,
  labelFixedWidth,
  subtitle,
  label,
  error,
  onSelect,
}) => {
  const renderSelectItem = (item: SingleSelectItem) => {
    return (
      <MenuItem key={item.value} value={item.value}>
        {item.label}
      </MenuItem>
    );
  };

  const renderItemsGroup = (item: SingleSelectGroupItem) => {
    return [
      <ListSubheader>{item.label}</ListSubheader>,
      map(item.items, renderSelectItem),
    ];
  };

  const renderItems = (item: SingleSelectItem | SingleSelectGroupItem) => {
    return isSelectGroup(item)
      ? renderItemsGroup(item)
      : renderSelectItem(item);
  };

  const handleChange = (e: SelectChangeEvent) => {
    onSelect?.(e.target.value);
  };

  return (
    <Stack sx={style.container}>
      <Stack width={labelFixedWidth ? labelFixedWidth : '254px'}>
        {!!label && (
          <Typography
            variant="body1"
            sx={style.label}
            color={error ? 'error.main' : 'text.secondary'}
          >
            {label}
          </Typography>
        )}
        {!!subtitle && (
          <Typography
            variant="body2"
            color={error ? 'error.main' : 'text.primary'}
          >
            {subtitle}
          </Typography>
        )}
      </Stack>
      <Stack flex={1} overflow="hidden">
        <Select value={value} onChange={handleChange} sx={style.input}>
          {map(items, renderItems)}
        </Select>
      </Stack>
    </Stack>
  );
};

export default SingleSelect;
