import { useCallback, useEffect } from 'react';
import { Stack } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import LargeInfoBlock from '@/components/LargeInfoBlock';
import { Routes } from '@/constants/routes';

const ResetPasswordEmail = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const email = searchParams.get('email');

  const navigate = useNavigate();

  const navigateHome = useCallback(() => {
    navigate(Routes.Root, { replace: true });
  }, [navigate]);

  useEffect(() => {
    if (!email) {
      navigateHome();
    }
  }, [email, navigateHome]);

  return (
    <Stack flex={1} alignItems="center" justifyContent="center">
      <LargeInfoBlock
        icon="EmailLarge"
        title={t('auth:emailConfirmation.title')}
        description={
          <Trans
            i18nKey="auth:emailConfirmation.description"
            values={{ email }}
            components={{
              bold: <strong />,
            }}
          />
        }
        action={t('auth:emailConfirmation.action')}
        onActionClick={navigateHome}
      />
    </Stack>
  );
};

export default ResetPasswordEmail;
