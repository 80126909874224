import { FC, useCallback, useMemo } from 'react';
import { ButtonBase, Stack } from '@mui/material';

import Icon from '@/components/ui/Icon';

import { IconType } from '@/assets/icons';
import { SXStyleSheet } from '@/utils';

import style from './style';

export interface SideBarItemData {
  path: string;
  icon?: IconType;
  iconActive?: IconType;
  items?: SideBarItemData[];
}

export interface SideBarItemProps {
  item: SideBarItemData;
  active?: boolean;
  altColor?: boolean;
  onSideBarItemClick?: (item: SideBarItemData) => void;
}

const SideBarItem: FC<SideBarItemProps> = ({
  item,
  active,
  altColor,
  onSideBarItemClick,
}) => {
  const handleClick = useCallback(() => {
    onSideBarItemClick?.(item);
  }, [item, onSideBarItemClick]);

  const itemIcon = useMemo(() => {
    if (!!item.icon && !!item.iconActive) {
      return active ? item.iconActive : item.icon;
    }
    return item.icon;
  }, [active, item.icon, item.iconActive]);

  return (
    <Stack mb={6}>
      <Stack
        sx={SXStyleSheet.merge(
          style.container,
          active && style.containerActive,
          altColor && style.containerAltColor,
          altColor && active && style.altColorActive,
        )}
      >
        <ButtonBase
          sx={SXStyleSheet.merge(
            style.buttonContainer,
            active && style.buttonContainerActive,
          )}
          disableRipple={true}
          onClick={handleClick}
        >
          {!!itemIcon && <Icon icon={itemIcon} />}
        </ButtonBase>
      </Stack>
    </Stack>
  );
};

export default SideBarItem;
