import { FC } from 'react';
import { Stack, Typography } from '@mui/material';

import Icon from '@/components/ui/Icon';

import { IconType } from '@/assets/icons';

import style from './style';

export interface IconBadgeProps {
  label: string;
  icon: IconType;
}

const IconBadge: FC<IconBadgeProps> = ({ label, icon }) => {
  return (
    <Stack sx={style.container}>
      <Icon icon={icon} />
      <Typography>{label}</Typography>
    </Stack>
  );
};

export default IconBadge;
