import { SXStyleSheet } from '@/utils';
import { tableCellClasses } from '@mui/material';

const style = SXStyleSheet.create({
  container: {
    backgroundColor: '#1EB2C3',
    height: '44px',
    [`.${tableCellClasses.root}`]: {
      padding: 0,
      px: 6,
      fontSize: '14px',
      color: 'primary.contrastText',
      backgroundColor: '#1EB2C3',
      borderRight: '1px solid',
      borderRightColor: 'divider',
      [`&:first-child`]: {
        borderTopLeftRadius: 12,
      },
      [`&:last-child`]: {
        borderTopRightRadius: 12,
        borderRight: 'none',
      },
    },
  },
  cell: {
    whiteSpace: 'nowrap',
    minWidth: '200px',
  },
  icon: {
    cursor: 'pointer',
  },
});

export default style;
