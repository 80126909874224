import { FC, useCallback } from 'react';

import TextInput from '@/components/ui/TextInput';
import TextInputInline, {
  TextInputInlineProps,
} from '@/components/ui/TextInputInline';

import useFormField from '@/hooks/useFormField';

export interface TextFieldProps
  extends Omit<
    TextInputInlineProps,
    'value' | 'error' | 'onTextChange' | 'touched' | 'onBlur'
  > {
  fieldName: string;
  inline?: boolean;
}

const TextField: FC<TextFieldProps> = ({ fieldName, inline, ...props }) => {
  const { value, error, setValue, onBlur, touched } = useFormField<string>(
    fieldName,
    '',
  );

  const handleTextChange = useCallback(
    (text: string) => {
      setValue(text);
    },
    [setValue],
  );

  return inline ? (
    <TextInputInline
      {...props}
      error={error}
      value={value}
      onTextChange={handleTextChange}
      onBlur={onBlur}
      touched={touched}
    />
  ) : (
    <TextInput
      {...props}
      error={error}
      value={value}
      onTextChange={handleTextChange}
      onBlur={onBlur}
      touched={touched}
    />
  );
};

export default TextField;
