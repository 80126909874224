import { Stack } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { findIndex, map } from 'lodash';

import SideBarItem, { SideBarItemData } from '@/components/ui/SideBarItem';
import IconButton from '@/components/ui/IconButton';

import style from './style';
import { SXStyleSheet } from '@/utils';
import { useMatches } from 'react-router-dom';
import { Routes } from '@/constants/routes';

export interface SideBarProps {
  items: SideBarItemData[];
  endElement?: JSX.Element | false;
  altColor?: boolean;
  backLabel?: string;
  onGoBack?: () => void;
  onSideBarItemClick?: (item: SideBarItemData) => void;
}

const SideBar: FC<SideBarProps> = ({
  items,
  endElement,
  altColor,
  backLabel,
  onGoBack,
  onSideBarItemClick,
}) => {
  const paths = useMatches();

  const isActive = useCallback(
    (path: string) => {
      // @todo(KAN-140): this behaviour is for mock items, will be removed when all items in sidebar will be available
      if (path === Routes.Root) {
        return false;
      }

      return findIndex(paths, (paths) => paths.pathname.includes(path)) >= 0;
    },
    [paths],
  );

  const renderItems = (item: SideBarItemData, i: number) => {
    return (
      <SideBarItem
        item={item}
        key={i}
        active={isActive(item.path)}
        altColor={altColor}
        onSideBarItemClick={onSideBarItemClick}
      />
    );
  };

  const isBackButtonVisible = useMemo(() => {
    return !!onGoBack && !!backLabel;
  }, [backLabel, onGoBack]);

  return (
    <Stack
      sx={SXStyleSheet.merge(
        style.container,
        altColor && style.altColorContainer,
      )}
    >
      {isBackButtonVisible && (
        <IconButton icon="GoBack" variant="light" onClick={onGoBack} />
      )}
      <Stack
        sx={SXStyleSheet.merge(
          style.itemsContainer,
          isBackButtonVisible && style.noLogo,
        )}
      >
        {map(items, renderItems)}
      </Stack>
      {!!endElement && (
        <Stack alignItems="center" justifyContent="center" width="100%">
          {endElement}
        </Stack>
      )}
    </Stack>
  );
};

export default SideBar;
