import { FC } from 'react';
import { Box, Typography } from '@mui/material';

import { SXStyleSheet } from '@/utils';

import style from './style';
import { useTranslation } from 'react-i18next';
import { getTranslationKey } from '@/utils/system';

export interface UserStatusProps {
  status: 'disabled' | 'active';
}

const UserStatus: FC<UserStatusProps> = ({ status }) => {
  const { t } = useTranslation();

  return (
    <Box sx={SXStyleSheet.merge(style.container, style[status])}>
      <Typography variant="caption">
        {t(
          getTranslationKey(
            `admin:userStatus.${status}`,
            'errors:custom.noTranslation',
          ),
        )}
      </Typography>
    </Box>
  );
};

export default UserStatus;
