import Images, { ImageType } from '@/assets/images';
import { Box, Stack, Typography } from '@mui/material';
import { SXStyleSheet } from '@/utils';
import { FC } from 'react';

export interface ImageCardProps {
  image: ImageType;
  title: string;
  subtitle: string;
}

const ImageCard: FC<ImageCardProps> = ({ title, subtitle, image }) => {
  return (
    <Stack flex={1} p="25px">
      <Stack
        sx={SXStyleSheet.merge(styles.container, {
          background: `url("${Images[image]}") lightgray 50% / cover no-repeat`,
        })}
      >
        <Box style={styles.textBackdrop} />
        <Stack zIndex={1} gap={6}>
          <Typography
            variant="h1"
            sx={styles.title}
            color="primary.contrastText"
          >
            {title}
          </Typography>
          <Typography sx={styles.subtitle} color="primary.contrastText">
            {subtitle}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

const styles = SXStyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    borderRadius: 4,
    justifyContent: 'flex-end',
    p: 8.5,
    position: 'relative',
    overflow: 'hidden',
  },
  textBackdrop: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    background:
      'radial-gradient(ellipse 100% 60% at 50% 80%, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0))',
    width: '100%',
    height: '300px',
  },
  title: {
    fontSize: '32px',
    fontWeight: 600,
  },
  subtitle: {
    fontSize: '20px',
    fontStyle: 'italic',
  },
});

export default ImageCard;
