import { SXStyleSheet } from '@/utils';

const style = SXStyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 2,
  },
});

export default style;
