import React, { FC, useMemo, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import {
  DatePicker as XDatePicker,
  DateView,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment';

import IconButton from '@/components/ui/IconButton';

import style from './style';
import { DateFormat } from '@/constants/form';

export interface DatePickerProps {
  value: string;
  label?: string;
  subtitle?: string;
  error?: string | null;
  onSelect?: (value: string) => void;
  format?: string;
  saveFormat?: string;
  views?: DateView[];
  labelFixedWidth?: string;
  min?: Moment;
  max?: Moment;
}

const DatePicker: FC<DatePickerProps> = ({
  value,
  label,
  labelFixedWidth,
  subtitle,
  error,
  onSelect,
  format,
  views,
  min,
  max,
  saveFormat,
}) => {
  const [datePickerOpened, setDatePickerOpened] = useState(false);

  const momentValue = useMemo(() => {
    return value ? moment(value) : null;
  }, [value]);

  const handleChangePickerState = (state: boolean) => () => {
    setDatePickerOpened(state);
  };

  const handleSelect = (value: Moment | null) => {
    if (!value) {
      return;
    }

    onSelect?.(
      !saveFormat
        ? value.utc().startOf('d').toISOString()
        : value.utc().startOf('d').format(saveFormat),
    );
  };

  return (
    <Stack style={style.container}>
      <Stack width={labelFixedWidth ? labelFixedWidth : '254px'} gap={2}>
        {!!label && (
          <Typography
            variant="body1"
            sx={style.label}
            color={error ? 'error.main' : 'text.secondary'}
          >
            {label}
          </Typography>
        )}
        {!!subtitle && (
          <Typography
            variant="body2"
            color={error ? 'error.main' : 'text.primary'}
          >
            {subtitle}
          </Typography>
        )}
      </Stack>
      <Stack flex={1}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <XDatePicker
            value={momentValue}
            views={views}
            onChange={handleSelect}
            open={datePickerOpened}
            format={format || DateFormat}
            timezone="UTC"
            onClose={handleChangePickerState(false)}
            sx={style.picker}
            maxDate={max}
            minDate={min}
            slots={{
              inputAdornment: () => (
                <IconButton
                  icon="Calendar"
                  onClick={handleChangePickerState(true)}
                />
              ),
            }}
          />
        </LocalizationProvider>
      </Stack>
    </Stack>
  );
};

export default DatePicker;
