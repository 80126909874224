import { Stack } from '@mui/material';
import Step, { StepData } from '@/components/ui/Step';
import { FC, useCallback } from 'react';
import { findIndex, map } from 'lodash';

import style from './style';

export interface StepperHeaderProps {
  active: string;
  steps: StepData[];
  onStepSelect?: (id: string) => void;
}

const StepperHeader: FC<StepperHeaderProps> = ({
  active,
  steps,
  onStepSelect,
}) => {
  const renderStep = useCallback(
    (step: StepData, i: number) => {
      const isStepActive = step.id === active;
      const isStepDone =
        findIndex(steps, { id: step.id }) < findIndex(steps, { id: active });

      return (
        <Step
          onClick={onStepSelect}
          isActive={isStepActive}
          isDone={isStepDone}
          isFirst={i === 0}
          isLast={i === steps.length - 1}
          {...step}
          key={step.id}
        />
      );
    },
    [active, onStepSelect, steps],
  );

  return steps.length > 1 ? (
    <Stack sx={style.container}>{map(steps, renderStep)}</Stack>
  ) : null;
};

export default StepperHeader;
