import { Stack } from '@mui/material';
import TextInput from '@/components/ui/TextInput';
import CTAButton from '@/components/ui/CTAButton';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface AdminFilterProps {
  onAddClick: () => void;
}

const AdminFilter: FC<AdminFilterProps> = ({ onAddClick }) => {
  // @todo(KAN-111): implement search
  const [searchValue, setSearchValue] = useState('');

  const { t } = useTranslation();

  return (
    <Stack alignItems="flex-start" gap={6}>
      <Stack width="100%" direction="row" justifyContent="space-between">
        <TextInput
          icon="Search"
          value={searchValue}
          onTextChange={setSearchValue}
          placeholder={t('admin:home.searchPlaceholder')}
          autoWidth
        />
        <CTAButton
          icon="Plus"
          label={t('admin:home.addUser')}
          variant="secondary"
          onClick={onAddClick}
          autoWidth
        />
      </Stack>
    </Stack>
  );
};

export default AdminFilter;
