import { FC } from 'react';
import { useFormikContext } from 'formik';

import CTAButton, { CTAButtonProps } from '@/components/ui/CTAButton';

export type SubmitButtonProps = Omit<
  CTAButtonProps,
  'onClick' | 'disabled' | 'loading'
>;

const SubmitButton: FC<SubmitButtonProps> = (props) => {
  const { submitForm, isSubmitting } = useFormikContext();

  return <CTAButton {...props} onClick={submitForm} loading={isSubmitting} />;
};

export default SubmitButton;
