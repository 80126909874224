import { Stack } from '@mui/material';
import { FC, JSX } from 'react';
import StepperHeader, {
  StepperHeaderProps,
} from '@/components/ui/StepperHeader';

import StepControl, { StepControlProps } from '@/components/ui/StepControl';

export type StepMap = Record<string, JSX.Element>;

export interface StepperProps extends StepperHeaderProps, StepControlProps {
  stepMap: StepMap;
  onStepSelect?: (id: string) => void;
}

const Stepper: FC<StepperProps> = ({
  steps,
  active,
  stepMap,
  onStepSelect,
  ...props
}) => {
  return (
    <Stack flex={1} overflow="hidden">
      <Stack p={6} flex={1} overflow="hidden">
        <StepperHeader
          active={active}
          steps={steps}
          onStepSelect={onStepSelect}
        />
        {stepMap[active]}
      </Stack>
      <StepControl {...props} />
    </Stack>
  );
};

export default Stepper;
