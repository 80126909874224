import { FC, useCallback } from 'react';
import { FieldArray } from 'formik';
import { map } from 'lodash';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import ChildForm, { childInitialValues } from '@/components/forms/ChildForm';
import TextButton from '@/components/ui/TextButton';

import { Child, CustomerFormProps } from '@/types';
import { getFormKey } from '@/utils/system';
import FormCard from '@/components/ui/FormCard';

const ChildrenForm: FC<CustomerFormProps> = ({ formKey }) => {
  const { t } = useTranslation();

  const renderChildForm = useCallback(
    (onDelete: (index: number) => void) => (_: Child, i: number) => {
      return (
        <ChildForm
          key={i}
          formKey={getFormKey(formKey, 'children')}
          index={i}
          onDelete={onDelete}
        />
      );
    },
    [formKey],
  );

  return (
    <FormCard p={0}>
      <FieldArray
        name={getFormKey(formKey, 'children')}
        render={(arrayHelpers) => {
          return (
            <Stack>
              {map(
                arrayHelpers.form.values[formKey]?.children,
                renderChildForm(arrayHelpers.remove),
              )}
              <Stack
                px={6}
                pt={6}
                flexDirection="row"
                justifyContent="flex-start"
              >
                <TextButton
                  text={t('customer:newCustomer.addChild')}
                  color="primary"
                  icon="AddPrimary"
                  onClick={() => {
                    arrayHelpers.push(childInitialValues);
                  }}
                />
              </Stack>
            </Stack>
          );
        }}
      />
    </FormCard>
  );
};

export default ChildrenForm;
