import {
  Stack,
  Typography,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormControl,
  FormHelperText,
  OutlinedInputProps,
} from '@mui/material';
import React, { ChangeEvent, FC, useCallback, useState } from 'react';
import style from '@/components/ui/TextInput/style';
import Icons, { IconType } from '@/assets/icons';
import Icon from '@/components/ui/Icon';
import { SXStyleSheet } from '@/utils';

type InputType = 'text' | 'password';

export interface TextInputProps extends Omit<OutlinedInputProps, 'error'> {
  value: string;
  label?: string;
  placeholder?: string;
  type?: InputType;
  error?: string;
  touched?: boolean;
  onTextChange?: (text: string) => void;
  icon?: IconType;
  hideErrorMessage?: boolean;
  autoWidth?: boolean;
}

const TextInput: FC<TextInputProps> = ({
  label,
  placeholder,
  onTextChange,
  type,
  value,
  error,
  touched,
  icon,
  hideErrorMessage,
  autoWidth,
  ...props
}) => {
  const [mouseEnter, setMouseEnter] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onTextChange?.(e.target.value);
    },
    [onTextChange],
  );

  const handleMouseOver = useCallback(
    (enter: boolean) => () => {
      setMouseEnter(enter);
    },
    [],
  );

  const toggleShowPassword = useCallback(() => {
    setShowPassword((prev) => !prev);
  }, []);

  const getAdornmentComponent = () => {
    if (type === 'password') {
      return (
        <IconButton
          onClick={toggleShowPassword}
          onMouseEnter={handleMouseOver(true)}
          onMouseLeave={handleMouseOver(false)}
        >
          {!mouseEnter && !!touched ? (
            !!error ? (
              <Icons.Error />
            ) : (
              <Icons.Checked />
            )
          ) : showPassword ? (
            <Icons.Visible />
          ) : (
            <Icons.Hidden />
          )}
        </IconButton>
      );
    }

    return (
      <Stack width={24}>
        {!!error ? <Icons.Error /> : !!touched ? <Icons.Checked /> : null}
      </Stack>
    );
  };

  return (
    <FormControl
      sx={SXStyleSheet.merge(!autoWidth && style.container)}
      error={!!error}
    >
      <Stack flex={1} gap={1.5}>
        {!!label && (
          <Typography
            variant="body2"
            sx={style.label}
            color={error ? 'error.main' : 'text.primary'}
          >
            {label}
          </Typography>
        )}
        <OutlinedInput
          value={value || ''}
          placeholder={placeholder}
          onChange={handleChange}
          type={type === 'password' && showPassword ? 'text' : type}
          size="small"
          aria-describedby="field-text-input-descriptor"
          sx={style.input}
          startAdornment={
            icon && <Icon sx={style.icon} icon={icon} size={24} />
          }
          endAdornment={
            <InputAdornment position="end">
              {getAdornmentComponent()}
            </InputAdornment>
          }
          {...props}
        />
        {!hideErrorMessage && (
          <FormHelperText sx={style.error} id="field-text-input-descriptor">
            {error}
          </FormHelperText>
        )}
      </Stack>
    </FormControl>
  );
};

export default TextInput;
