import { SideBarItemData } from '@/components/ui/SideBarItem';
import { Routes } from '@/constants/routes';

export const SideBarMaxLetterLength = 22;

export const SideBarAdminNavigation: SideBarItemData[] = [
  {
    icon: 'Home',
    iconActive: 'HomeActive',
    path: Routes.Admin,
  },
  {
    icon: 'Users',
    iconActive: 'UsersActive',
    path: Routes.Customers,
  },
];

export const SideBarConsultantNavigation: SideBarItemData[] = [
  {
    icon: 'Users',
    path: Routes.Customers,
  },
];

// @todo(KAN-92):connect with paths
export const NewCustomerSideBar: SideBarItemData[] = [
  {
    icon: 'AccountCircle',
    path: Routes.NewCustomer,
  },
  {
    icon: 'Money',
    path: '/',
  },
  {
    icon: 'Planner',
    path: '/',
  },
  {
    icon: 'TodoList',
    path: '/',
  },
  {
    icon: 'Idea',
    path: '/',
  },
  {
    icon: 'Wallet',
    path: '/',
  },
  {
    icon: 'Chart',
    path: '/',
  },
];

export const CustomerDetailsSideBar: SideBarItemData[] = [
  {
    icon: 'AccountCircle',
    path: Routes.CustomerDetails,
  },
  {
    icon: 'Money',
    path: '/',
  },
  {
    icon: 'Planner',
    path: '/',
  },
  {
    icon: 'TodoList',
    path: '/',
  },
  {
    icon: 'Idea',
    path: '/',
  },
  {
    icon: 'Wallet',
    path: '/',
  },
  {
    icon: 'Chart',
    path: '/',
  },
];
