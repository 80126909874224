import { SXStyleSheet } from '@/utils';
import { typographyClasses } from '@mui/material';

const style = SXStyleSheet.create({
  container: {
    px: 3,
    py: 1,
    borderRadius: 4,
    width: 'fit-content',
  },
  active: {
    backgroundColor: 'success.light',
    [`.${typographyClasses.root}`]: {
      color: 'success.contrastText',
    },
  },
  disabled: {
    backgroundColor: 'error.light',
    [`.${typographyClasses.root}`]: {
      color: 'error.contrastText',
    },
  },
});

export default style;
