import { Box, Stack, Typography } from '@mui/material';

import style from './style';
import { FC } from 'react';
import { truncate } from 'lodash';
import { SXStyleSheet } from '@/utils';

export interface StepData {
  id: string;
  title: string;
  disabled?: boolean;
}

export interface StepProps extends StepData {
  isActive?: boolean;
  isDone?: boolean;
  onClick?: (id: string) => void;
  isFirst?: boolean;
  isLast?: boolean;
}

const Step: FC<StepProps> = ({
  id,
  isActive,
  isDone,
  disabled,
  title,
  onClick,
  isFirst,
  isLast,
}) => {
  const handleClick = () => {
    if (disabled) {
      return;
    }

    onClick?.(id);
  };

  return (
    <Stack
      sx={SXStyleSheet.merge(
        style.container,
        isDone && style.containerDone,
        isActive && style.containerActive,
      )}
      onClick={handleClick}
    >
      {!isFirst && (
        <Box sx={SXStyleSheet.merge(style.arrow, style.arrowLeft)} />
      )}
      <Stack sx={SXStyleSheet.merge(style.titleContainer)}>
        <Typography fontWeight="bold" noWrap className="step-title">
          {truncate(title, { length: 18 })}
        </Typography>
      </Stack>
      {!isLast && (
        <Box
          sx={SXStyleSheet.merge(style.arrow, style.arrowRight)}
          className="step-arrow-right"
        />
      )}
    </Stack>
  );
};

export default Step;
