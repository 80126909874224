import Logo from './Logo.png';
import Logo2x from './Logo2x.png';
import Insurance from './Insurance.png';

const Image = {
  Logo,
  Insurance,
  Logo2x,
};

export type ImageType = keyof typeof Image;

export default Image;
