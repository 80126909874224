import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import { Stack } from '@mui/material';
import { findIndex, some } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Formik, FormikProps } from 'formik';

import TabsView from '@/components/ui/TabsView';
import SideBar from '@/components/ui/SideBar';
import Tabs from '@/components/ui/Tabs';
import Header from '@/components/Header';
import NewCustomerStepper, {
  NewCustomerStepperRef,
} from '@/components/NewCustomerStepper';
import ChildrenStepper from '@/components/ChildrenStepper';

import {
  CustomerDetailsSideBar,
  NewCustomerSideBar,
} from '@/constants/sidebar';
import { NewCustomerTab, NewCustomerTabItems } from '@/constants/tabs';
import { CustomerForm } from '@/types';
import { useMatches, useNavigate, useSearchParams } from 'react-router-dom';
import useCustomerStore from '@/store/customer';
import { getPath } from '@/utils';
import { Routes } from '@/constants/routes';
import { updateCustomerValidationSchema } from '@/constants/validation';
import api from '@/services/api';

const NewCustomerView = () => {
  const formRef = useRef<FormikProps<CustomerForm>>(null);
  const [formTouched, setFormTouched] = useState(false);

  const [searchParams] = useSearchParams();
  const matches = useMatches();

  const isCustomerDetails = useMemo(() => {
    return some(matches, (match) => {
      return match.pathname.includes(Routes.CustomerDetails);
    });
  }, [matches]);

  const customerId = searchParams.get('id');
  const {
    customer,
    partner,
    error,
    updateCustomer,
    fetchCustomer,
    createCustomer,
    clearState,
  } = useCustomerStore();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const stepperRef = useRef<
    Record<NewCustomerTab, MutableRefObject<NewCustomerStepperRef | null>>
  >({
    client: { current: null },
    partner: { current: null },
    children: { current: null },
  });

  const [activeTab, setActiveTab] = useState(NewCustomerTab.Client);

  useEffect(() => {
    if (!customerId) {
      return;
    }

    fetchCustomer('customer', customerId).catch(console.error);
  }, [customerId, fetchCustomer]);

  useEffect(() => {
    if (
      activeTab === NewCustomerTab.Partner &&
      !customer?.partner?.id &&
      customerId
    ) {
      createCustomer('partner', { partner_id: parseInt(customerId) }).catch(
        console.error,
      );
    }

    if (customer?.partner?.id) {
      fetchCustomer('partner', customer.partner.id).catch(console.error);
    }
  }, [
    activeTab,
    createCustomer,
    customer?.partner?.id,
    customerId,
    fetchCustomer,
  ]);

  useEffect(() => {
    if (customer || partner) {
      formRef.current?.setValues({
        customer,
        partner,
      });
    }
  }, [customer, partner]);

  const activeTabIndex = useMemo(() => {
    return findIndex(NewCustomerTabItems, { id: activeTab });
  }, [activeTab]);

  const handleSelectActiveTab = async (tab: string) => {
    await formRef.current?.submitForm();
    setActiveTab(tab as NewCustomerTab);
  };

  const handleSave = async () => {
    await formRef.current?.submitForm();
    navigate(getPath('..', Routes.Home, Routes.Customers));
  };

  const handleStepperEnd = () => {
    const nextTabIndex = activeTabIndex + 1;

    if (nextTabIndex < NewCustomerTabItems.length) {
      setActiveTab(NewCustomerTabItems[nextTabIndex].id as NewCustomerTab);
    }
  };

  const handleStepperStart = () => {
    const nextTabIndex = activeTabIndex - 1;

    if (nextTabIndex >= 0) {
      setActiveTab(NewCustomerTabItems[nextTabIndex].id as NewCustomerTab);
    }
  };

  const handleSubmit = async (data: CustomerForm) => {
    setFormTouched(true);

    if (!customerId && data.customer) {
      const { id } = await api.customer.createCustomer(data.customer);
      navigate(`?id=${id}`, { replace: true });
      return;
    }

    const updateKey =
      activeTab === NewCustomerTab.Partner ? 'partner' : 'customer';

    const updateId =
      activeTab === NewCustomerTab.Partner ? customer?.partner?.id : customerId;

    const updateData = data[updateKey];

    if (!updateId || !updateData) {
      return;
    }

    updateCustomer(updateKey, updateId, updateData).catch(console.error);
  };

  const handleExitFromCustomer = () => {
    clearState();
    navigate(getPath('..', Routes.Home, Routes.Customers));
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={{
        customer,
        partner,
      }}
      validationSchema={updateCustomerValidationSchema}
      onSubmit={handleSubmit}
    >
      <Stack
        flex={1}
        direction="row"
        overflow="hidden"
        bgcolor="background.screen"
      >
        {/* @todo(KAN-92): update the side bar position to root?*/}
        <SideBar
          items={
            isCustomerDetails ? CustomerDetailsSideBar : NewCustomerSideBar
          }
          backLabel={t('customer:newCustomer.goBack')}
          onGoBack={handleExitFromCustomer}
        />
        <Stack flex={1} pb={7} overflow="hidden">
          <Header
            title={t(
              isCustomerDetails
                ? 'customer:customerDetails.title'
                : 'customer:newCustomer.title',
            )}
          />
          <Stack flex={1} px={6} gap={3} overflow="hidden">
            <Tabs
              activeTab={activeTab}
              items={NewCustomerTabItems}
              onSelect={handleSelectActiveTab}
            />
            <TabsView
              activeTabIndex={activeTabIndex}
              tabs={[
                <NewCustomerStepper
                  ref={stepperRef.current.client}
                  onStepperEnd={handleStepperEnd}
                  isCustomerDetails={isCustomerDetails}
                  onStepperStart={handleStepperStart}
                  formType="customer"
                  hasError={!!error}
                  onCancel={handleExitFromCustomer}
                  formTouched={formTouched}
                />,
                <NewCustomerStepper
                  ref={stepperRef.current.partner}
                  onStepperEnd={handleStepperEnd}
                  onStepperStart={handleStepperStart}
                  isCustomerDetails={isCustomerDetails}
                  hasError={!!error}
                  formType="partner"
                  onCancel={handleExitFromCustomer}
                  formTouched={formTouched}
                />,
                <ChildrenStepper
                  ref={stepperRef.current.children}
                  onStepperStart={handleStepperStart}
                  hasError={!!error}
                  onFinishForm={handleSave}
                  onCancel={handleExitFromCustomer}
                  formTouched={formTouched}
                />,
              ]}
            />
          </Stack>
        </Stack>
      </Stack>
    </Formik>
  );
};

export default NewCustomerView;
