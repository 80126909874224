import { SXStyleSheet } from '@/utils';

const style = SXStyleSheet.create({
  container: {
    flexDirection: 'row',
    pb: 4,
    gap: 4,
  },
});

export default style;
