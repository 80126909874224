import { SXStyleSheet } from '@/utils';
import { tabClasses, tabsClasses } from '@mui/material';

const style = SXStyleSheet.create({
  container: {
    [`.${tabsClasses.flexContainer}`]: {
      gap: 4,
    },
    [`.${tabsClasses.indicator}`]: {
      backgroundColor: 'text.secondary',
    },
  },
  border: {
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderColor: 'divider',
  },
  tabContainer: {
    px: 0,
    minWidth: 40,
    color: 'text.primary',
    fontSize: '14px',
    [`&.${tabClasses.selected}`]: {
      color: 'text.secondary',
    },
  },
});

export default style;
