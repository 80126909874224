import { SXStyleSheet } from '@/utils';

const style = SXStyleSheet.create({
  container: {
    backgroundColor: 'background.paper',
    borderRadius: 3,
    overflow: 'auto',
  },
});

export default style;
