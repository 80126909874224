import { SXStyleSheet } from '@/utils';
import { tableCellClasses } from '@mui/material';

const style = SXStyleSheet.create({
  row: {
    height: '72px',
    [`.${tableCellClasses.root}`]: {
      padding: 0,
      px: 6,
      borderColor: 'divider',
      borderLeft: '1px solid',
      borderLeftColor: 'divider',
      '&:first-child': {
        borderLeft: 'none',
      },
    },
    '&:hover': {
      backgroundColor: 'primary.hover',
      [`.${tableCellClasses.root}`]: {
        backgroundColor: 'primary.hover',
      },
    },
  },
  actionCell: {
    backgroundColor: 'grey.A200',
  },
});

export default style;
