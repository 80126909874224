import { SXStyleSheet } from '@/utils';
import { outlinedInputClasses } from '@mui/material';

const style = SXStyleSheet.create({
  container: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    fontWeight: 500,
  },
  input: {
    [`&.${outlinedInputClasses.root}`]: {
      height: '58px',
    },
  },
});

export default style;
