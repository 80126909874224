import { IconButton as MUIIconButton, SxProps, Theme } from '@mui/material';
import { IconType } from '@/assets/icons';
import Icon from '@/components/ui/Icon';
import { FC } from 'react';

import { SXStyleSheet } from '@/utils';
import style from './style';

export interface IconButtonProps {
  icon: IconType;
  onClick?: () => void;
  sx?: SxProps<Theme>;
  iconSx?: SxProps<Theme>;
  disabled?: boolean;
  size?: number;
  variant?: 'icon' | 'outlined' | 'light' | 'primary';
}

const IconButton: FC<IconButtonProps> = ({
  icon,
  onClick,
  sx,
  disabled,
  iconSx,
  size,
  variant = 'icon',
}) => {
  return (
    <MUIIconButton
      sx={SXStyleSheet.merge(sx, style[variant])}
      onClick={onClick}
      disabled={disabled}
    >
      <Icon
        sx={SXStyleSheet.merge(iconSx, { width: size, height: size })}
        icon={icon}
      />
    </MUIIconButton>
  );
};

export default IconButton;
