import { Stack, Table as MUITable, TableBody } from '@mui/material';
import { map } from 'lodash';

import TableHeader, { TableItemConfig } from '@/components/ui/TableHeader';

export interface TableProps<T extends object> {
  data: T[];
  trKey: string;
  renderRow: (data: T, i: number) => JSX.Element | null;
  renderTableTitle?: () => JSX.Element;
  headerItems: TableItemConfig<T>[];
}

const Table = <T extends object>({
  data,
  renderRow,
  trKey,
  headerItems,
  renderTableTitle,
}: TableProps<T>) => {
  return (
    <Stack flex={1} overflow="hidden">
      {!!renderTableTitle && renderTableTitle()}
      <Stack flex={1} maxHeight="100%" maxWidth="100%" overflow="auto">
        <MUITable stickyHeader>
          <TableHeader trKey={trKey} items={headerItems} />
          <TableBody>{map(data, renderRow)}</TableBody>
        </MUITable>
      </Stack>
    </Stack>
  );
};

export default Table;
