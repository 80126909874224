import { useTranslation } from 'react-i18next';
import { FC } from 'react';

import TextField from '@/components/formControl/TextField';
import SelectField from '@/components/formControl/SelectField';

import { getFormKey, showIf } from '@/utils/system';
import {
  CustomerForm,
  CustomerFormProps,
  ExperienceLevel,
  SalaryGroup,
} from '@/types';
import { useFormikContext } from 'formik';
import useTranslatedEnum from '@/hooks/useTranslatedEnum';
import { customerVisibilityKeys } from '@/constants/form';
import FormCard from '@/components/ui/FormCard';

const IncomeForm: FC<CustomerFormProps> = ({ formKey }) => {
  const { values } = useFormikContext<CustomerForm>();

  const { t } = useTranslation();
  const [experienceLevel] = useTranslatedEnum(
    'ExperienceLevel',
    ExperienceLevel,
  );
  const [salaryGroup] = useTranslatedEnum('SalaryGroup', SalaryGroup);

  return (
    <FormCard>
      {showIf(
        values[formKey],
        'employment_status',
        customerVisibilityKeys.grossNetSalary,
        <TextField
          inline
          hideErrorMessage
          fieldName={getFormKey(formKey, 'gross_salary')}
          label={t('customer:newCustomer.form.grossSalary.label')}
        />,
      )}
      {showIf(
        values[formKey],
        'employment_status',
        customerVisibilityKeys.grossNetSalary,
        <TextField
          inline
          hideErrorMessage
          fieldName={getFormKey(formKey, 'net_salary')}
          label={t('customer:newCustomer.form.netSalary.label')}
        />,
      )}
      {showIf(
        values[formKey],
        'employment_status',
        customerVisibilityKeys.salaryCivilServant,
        <SelectField
          fieldName={getFormKey(formKey, 'salary_group')}
          items={salaryGroup}
          label={t('customer:newCustomer.form.salaryGroup.label')}
        />,
      )}
      {showIf(
        values[formKey],
        'employment_status',
        customerVisibilityKeys.salaryCivilServant,
        <SelectField
          fieldName={getFormKey(formKey, 'experience_level')}
          items={experienceLevel}
          label={t('customer:newCustomer.form.experienceLevel.label')}
        />,
      )}
      {showIf(
        values[formKey],
        'employment_status',
        customerVisibilityKeys.income,
        <TextField
          inline
          hideErrorMessage
          fieldName={getFormKey(formKey, 'additional_income_type')}
          label={t('customer:newCustomer.form.additionalIncomeType.label')}
        />,
      )}
      {showIf(
        values[formKey],
        'employment_status',
        customerVisibilityKeys.income,
        <TextField
          inline
          hideErrorMessage
          fieldName={getFormKey(formKey, 'additional_income_amount')}
          label={t('customer:newCustomer.form.additionalIncomeAmount.label')}
        />,
      )}
    </FormCard>
  );
};

export default IncomeForm;
