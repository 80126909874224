import { TableItemConfig } from '@/components/ui/TableHeader';
import { Customer, User } from '@/types';
import moment from 'moment';
import i18n from 'i18next';
import { DateFormat } from '@/constants/form';

export const AdminTableColumns: TableItemConfig<User>[] = [
  {
    sortKey: 'name',
    sortIcon: 'Sort',
    label: 'name',
    key: 'name',
  },
  {
    sortKey: 'email',
    sortIcon: 'Sort',
    label: 'email',
    key: 'email',
  },
  {
    label: 'role',
    key: 'role',
  },
  {
    label: 'status',
    sortIcon: 'Sort',
    key: 'status',
  },
  {
    label: 'clients',
  },
  {
    label: 'action',
    width: 81,
  },
];

export const CustomerTableConfig: TableItemConfig<Customer>[] = [
  // Personal Details
  {
    label: 'fullName',
    key: ['first_name', 'last_name'],
  },
  {
    label: 'dateOfBirth',
    key: 'date_of_birth',
    transformValue: (value) => {
      if (typeof value !== 'string') {
        return null;
      }

      return moment(value).format(DateFormat);
    },
  },
  {
    label: 'maritalStatus',
    key: 'marital_status',
    transformValue: (value) => {
      if (typeof value !== 'string') {
        return null;
      }

      return i18n.t(`enums:MaritalStatus.${value}`);
    },
  },
  {
    label: 'address',
    key: 'address',
  },
  {
    label: 'postalCode',
    key: 'postal_code',
  },
  {
    label: 'city',
    key: 'city',
  },
  {
    label: 'weight',
    key: 'weight',
  },
  {
    label: 'height',
    key: 'height',
  },
  {
    label: 'isSmoker',
    key: 'is_smoker',
    transformValue: (value) => {
      if (typeof value !== 'boolean') {
        return null;
      }

      return i18n.t(value ? 'enums:YesNo.yes' : 'enums:YesNo.no');
    },
  },
  // Work
  {
    label: 'employmentStatus',
    key: 'employment_status',
    transformValue: (value) => {
      if (typeof value !== 'string') {
        return null;
      }

      return i18n.t(
        value
          ? `enums:EmploymentStatus.${value}`
          : `enums:EmploymentStatus.${value}`,
      );
    },
  },
  {
    label: 'jobTitle',
    key: 'job_title',
  },
  {
    label: 'legalForm',
    key: 'legal_form',
    transformValue: (value) => {
      if (typeof value !== 'string') {
        return null;
      }

      return i18n.t(
        value ? `enums:LegalForm.${value}` : `enums:LegalForm.${value}`,
      );
    },
  },
  {
    label: 'employeeCount',
    key: 'employee_count',
  },
  {
    label: 'revenue',
    key: 'revenue',
    transformValue: (value) => {
      if (!value) {
        return null;
      }

      return i18n.t(`common:currency`, { value });
    },
  },
  {
    label: 'profit',
    key: 'profit',
    transformValue: (value) => {
      if (!value) {
        return null;
      }

      return i18n.t(`common:currency`, { value });
    },
  },
  {
    label: 'pensionInsuranceStatus',
    key: 'pension_insurance_status',
    transformValue: (value) => {
      if (typeof value !== 'string') {
        return null;
      }

      return i18n.t(
        value
          ? `enums:PensionInsuranceStatus.${value}`
          : `enums:PensionInsuranceStatus.${value}`,
      );
    },
  },
  // Insurance
  {
    label: 'healthInsuranceStatus',
    key: 'health_insurance_status',
    transformValue: (value) => {
      if (typeof value !== 'string') {
        return null;
      }

      return i18n.t(
        value
          ? `enums:HealthInsuranceStatus.${value}`
          : `enums:HealthInsuranceStatus.${value}`,
      );
    },
  },
  {
    label: 'healthInsuranceProvider',
    key: 'health_insurance_provider',
  },
  // Income
  {
    label: 'grossSalary',
    key: 'gross_salary',
    transformValue: (value) => {
      if (!value) {
        return null;
      }

      return i18n.t(`common:currency`, { value });
    },
  },
  {
    label: 'netSalary',
    key: 'net_salary',
    transformValue: (value) => {
      if (!value) {
        return null;
      }

      return i18n.t(`common:currency`, { value });
    },
  },
  {
    label: 'salaryGroup',
    key: 'salary_group',
    transformValue: (value) => {
      if (typeof value !== 'string') {
        return null;
      }

      return i18n.t(
        value ? `enums:SalaryGroup.${value}` : `enums:SalaryGroup.${value}`,
      );
    },
  },
  {
    label: 'experienceLevel',
    key: 'experience_level',
    transformValue: (value) => {
      if (typeof value !== 'string') {
        return null;
      }

      return i18n.t(
        value
          ? `enums:ExperienceLevel.${value}`
          : `enums:ExperienceLevel.${value}`,
      );
    },
  },
  {
    label: 'additionalIncomeType',
    key: 'additional_income_type',
  },
  {
    label: 'additionalIncomeAmount',
    key: 'additional_income_amount',
  },
  {
    label: 'childBenefitEligibleChildren',
    key: 'child_benefit_eligible_children',
  },
  {
    label: 'childBenefitNonEligibleChildren',
    key: 'child_benefit_non_eligible_children',
  },
];
