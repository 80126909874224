import { Stack } from '@mui/material';
import { SXStyleSheet } from '@/utils';

const TextInputGhost = () => {
  return (
    <Stack sx={style.container} direction="row" alignItems="center" gap="136px">
      <Stack sx={style.textGhost} />
      <Stack sx={style.inputGhost} />
    </Stack>
  );
};

const style = SXStyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
  },
  textGhost: {
    width: '118px',
    height: '20px',
    background: '#ECF1F4',
    borderRadius: 2,
  },
  inputGhost: {
    flex: 1,
    height: '56px',
    borderRadius: 2,
    background:
      'linear-gradient(90deg, #E3EBF0 0%, rgba(236, 241, 244, 0.46) 56.4%, #E3EBF0 100%)',
    animation: 'gradient-animation 2s infinite',
    backgroundSize: '200% 100%',
    '@keyframes gradient-animation': {
      '0%': {
        backgroundPosition: '200% 0',
      },
      '100%': {
        backgroundPosition: '0 0',
      },
    },
  },
});

export default TextInputGhost;
