import { SXStyleSheet } from '@/utils';

const style = SXStyleSheet.create({
  text: {
    textDecorationLine: 'underline',
    cursor: 'pointer',
  },
  textVariants: {
    large: {
      fontSize: '16px',
    },
    small: {
      fontSize: '12px',
    },
    medium: {
      fontSize: '14px',
    },
  },
});

export default style;
