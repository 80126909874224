import DatePicker, { DatePickerProps } from '@/components/ui/DatePicker';
import { FC } from 'react';
import useFormField from '@/hooks/useFormField';

export interface DateFieldProps
  extends Omit<DatePickerProps, 'value' | 'error' | 'onSelect'> {
  fieldName: string;
}

const DateField: FC<DateFieldProps> = ({ fieldName, ...props }) => {
  const { value, error, setValue } = useFormField<string>(fieldName, '');

  return (
    <DatePicker value={value} error={error} onSelect={setValue} {...props} />
  );
};

export default DateField;
