import { useTranslation } from 'react-i18next';
import { FC } from 'react';

import TextField from '@/components/formControl/TextField';
import DateField from '@/components/formControl/DateField';
import SelectField from '@/components/formControl/SelectField';
import useTranslatedEnum from '@/hooks/useTranslatedEnum';
import RadioSelectField from '@/components/formControl/RadioSelectField';

import { CustomerFormProps, MaritalStatus, YesNo } from '@/types';
import { getFormKey } from '@/utils/system';
import moment from 'moment';
import FormCard from '@/components/ui/FormCard';
import { Stack, Typography } from '@mui/material';

const PersonalDetailsForm: FC<CustomerFormProps> = ({ formKey }) => {
  const { t } = useTranslation();

  const [maritalStatus] = useTranslatedEnum('MaritalStatus', MaritalStatus);
  const [yseNo] = useTranslatedEnum('YesNo', YesNo);

  const radioValueMapper = (
    value: string | number | boolean,
  ): string | undefined => {
    if (typeof value !== 'boolean') {
      return;
    }

    return value ? YesNo.Yes : YesNo.No;
  };

  const radioValueSelectMapper = (value: string) => {
    return value === YesNo.Yes;
  };

  return (
    <FormCard>
      <Typography variant="overline" pb={4}>
        {t('customer:newCustomer.mainInfo')}
      </Typography>
      <Stack direction="row" alignItems="flex-start" gap={14}>
        <Stack flex={1} gap={6}>
          <TextField
            inline
            hideErrorMessage
            fieldName={getFormKey(formKey, 'first_name')}
            label={t('customer:newCustomer.form.firstName.label')}
          />
          <TextField
            inline
            hideErrorMessage
            fieldName={getFormKey(formKey, 'last_name')}
            label={t('customer:newCustomer.form.lastName.label')}
          />
          <DateField
            fieldName={getFormKey(formKey, 'date_of_birth')}
            label={t('customer:newCustomer.form.dateOfBirth.label')}
            saveFormat="YYYY-MM-DD"
            max={moment()}
          />
          <SelectField
            fieldName={getFormKey(formKey, 'marital_status')}
            items={maritalStatus}
            label={t('customer:newCustomer.form.maritalStatus.label')}
          />
        </Stack>
        <Stack flex={1} gap={6}>
          <TextField
            inline
            hideErrorMessage
            fieldName={getFormKey(formKey, 'postal_code')}
            label={t('customer:newCustomer.form.postalCode.label')}
          />
          <TextField
            inline
            hideErrorMessage
            fieldName={getFormKey(formKey, 'city')}
            label={t('customer:newCustomer.form.city.label')}
          />
          <TextField
            inline
            hideErrorMessage
            fieldName={getFormKey(formKey, 'address')}
            label={t('customer:newCustomer.form.address.label')}
          />
        </Stack>
      </Stack>
      <Typography variant="overline" py={4}>
        {t('customer:newCustomer.additionalInfo')}
      </Typography>
      <Stack gap={14} direction="row">
        <Stack flex={1} gap={6}>
          <TextField
            inline
            hideErrorMessage
            fieldName={getFormKey(formKey, 'weight')}
            label={t('customer:newCustomer.form.weight.label')}
          />
          <TextField
            inline
            hideErrorMessage
            fieldName={getFormKey(formKey, 'height')}
            label={t('customer:newCustomer.form.height.label')}
          />
        </Stack>
        <Stack flex={1}>
          <RadioSelectField
            options={yseNo}
            fieldName={getFormKey(formKey, 'is_smoker')}
            valueTransformer={radioValueMapper}
            selectTransformer={radioValueSelectMapper}
            label={t('customer:newCustomer.form.isSmoker.label')}
          />
        </Stack>
      </Stack>
    </FormCard>
  );
};

export default PersonalDetailsForm;
