import CheckBox, { CheckBoxProps } from '@/components/ui/CheckBox';
import { FC } from 'react';

import useFormField from '@/hooks/useFormField';

export type CheckBoxFieldProps = Omit<CheckBoxProps, 'value' | 'onChange'> & {
  fieldName: string;
};

const CheckBoxField: FC<CheckBoxFieldProps> = ({ fieldName, ...props }) => {
  const { value, setValue } = useFormField<string>(fieldName, '');

  const handleChange = (value: string) => {
    setValue(value);
  };

  return <CheckBox value={value} onChange={handleChange} {...props} />;
};

export default CheckBoxField;
