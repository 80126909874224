import { Stack } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Image from '@/components/ui/Image';
import ImageCard from '@/components/ImageCard';
import CopyrightNotice from '@/components/CopyrightNotice';
import { SXStyleSheet } from '@/utils';

const AuthView = () => {
  const { t } = useTranslation();

  return (
    <Stack flex={1} flexDirection="row" sx={style.container}>
      <Stack flex={1}>
        <Stack
          flex={1}
          pt="25px"
          alignItems="center"
          justifyContent="center"
          gap="89px"
        >
          <Image image="Logo2x" width="351px" />
          <Stack width="363px">
            <Outlet />
          </Stack>
        </Stack>
        <CopyrightNotice />
      </Stack>
      <ImageCard
        image="Insurance"
        title={t('auth:side.title')}
        subtitle={t('auth:side.subtitle')}
      />
    </Stack>
  );
};

const style = SXStyleSheet.create({
  container: {
    background:
      'linear-gradient(301deg, #FFF 42.63%, #A5ADC0 161.1%, rgba(0, 0, 0, 0.50) 289.44%), #FFF',
  },
});

export default AuthView;
