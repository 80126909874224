import { SXStyleSheet } from '@/utils';
import { outlinedInputClasses } from '@mui/material';

const style = SXStyleSheet.create({
  label: {
    fontWeight: 500,
  },
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  picker: {
    [`.${outlinedInputClasses.root}`]: {
      height: '58px',
    },
  },
});

export default style;
