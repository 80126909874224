import * as yup from 'yup';
import {
  EmploymentStatus,
  ExperienceLevel,
  HealthInsuranceStatus,
  LegalForm,
  MaritalStatus,
  PensionInsuranceStatus,
  SalaryGroup,
} from '@/types';

export const signInValidationSchema = yup.object().shape({
  email: yup
    .string()
    .matches(
      /^(?!.*\.\.)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      'email.notMatch',
    )
    .required('email.required'),
  password: yup.string().required('password.required'),
});

export const resetPasswordValidationSchema = yup.object().shape({
  email: yup
    .string()
    .matches(
      /^(?!.*\.\.)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      'email.notMatch',
    )
    .required('email.required'),
});

export const newPasswordValidationSchema = yup.object().shape({
  password: yup.string().required('password.required'),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref('password')], 'password.required')
    .required('password.required'),
});

export const newUserValidationSchema = yup.object().shape({
  email: yup
    .string()
    .matches(
      /^(?!.*\.\.)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      'email.notMatch',
    )
    .required('email.required'),
  password: yup.string().required('password.required'),
  name: yup.string().required('name.required'),
});

export const newCustomerValidationSchema = yup.object().shape({
  first_name: yup.string().required('field.required').max(50, 'field.length'),
  last_name: yup.string().required('field.required').max(50, 'field.length'),
  date_of_birth: yup.date().nullable(),
  address: yup.string().nullable().max(100, 'field.length'),
  postal_code: yup.string().nullable().max(5, 'field.length'),
  city: yup.string().nullable().max(50, 'field.length'),
  company_purpose: yup.string().nullable().max(100, 'field.length'),
  job_title: yup.string().nullable().max(100, 'field.length'),
  health_insurance_provider: yup.string().nullable().max(100, 'field.length'),
  additional_income_type: yup.string().nullable().max(50, 'field.length'),
  highest_education: yup.string().nullable().max(100, 'field.length'),
  marital_status: yup.string().nullable().oneOf(Object.values(MaritalStatus)),
  employment_status: yup
    .string()
    .nullable()
    .oneOf(Object.values(EmploymentStatus), 'field.invalidValue'),
  health_insurance_status: yup
    .string()
    .nullable()
    .oneOf(Object.values(HealthInsuranceStatus)),
  legal_form: yup
    .string()
    .nullable()
    .oneOf(Object.values(LegalForm), 'field.invalidValue'),
  pension_insurance_status: yup
    .string()
    .nullable()
    .oneOf(Object.values(PensionInsuranceStatus)),
  salary_group: yup
    .string()
    .nullable()
    .oneOf(Object.values(SalaryGroup), 'field.invalidValue'),
  experience_level: yup
    .string()
    .nullable()
    .oneOf(Object.values(ExperienceLevel), 'field.invalidValue'),
  gross_salary: yup.number().nullable(),
  net_salary: yup.number().nullable(),
  employee_count: yup.number().nullable(),
  child_benefit_eligible_children: yup.number().nullable(),
  child_benefit_non_eligible_children: yup.number().nullable(),
  is_smoker: yup.boolean().nullable(),
  children: yup
    .array()
    .nullable()
    .of(
      yup.object().shape({
        name: yup.string().required('field.required'),
        year_of_birth: yup
          .string()
          .max(4, 'field.invalidValue')
          .min(4, 'field.invalidValue'),
      }),
    ),
});

export const partnerValidationSchema = yup.object().shape({
  first_name: yup.string().nullable().max(50, 'field.length'),
  last_name: yup.string().nullable().max(50, 'field.length'),
  date_of_birth: yup.date().nullable(),
  address: yup.string().nullable().max(100, 'field.length'),
  postal_code: yup.string().nullable().max(5, 'field.length'),
  city: yup.string().nullable().max(50, 'field.length'),
  company_purpose: yup.string().nullable().max(100, 'field.length'),
  job_title: yup.string().nullable().max(100, 'field.length'),
  health_insurance_provider: yup.string().nullable().max(100, 'field.length'),
  additional_income_type: yup.string().nullable().max(50, 'field.length'),
  highest_education: yup.string().nullable().max(100, 'field.length'),
  marital_status: yup.string().nullable().oneOf(Object.values(MaritalStatus)),
  employment_status: yup
    .string()
    .nullable()
    .oneOf(Object.values(EmploymentStatus), 'field.invalidValue'),
  health_insurance_status: yup
    .string()
    .nullable()
    .oneOf(Object.values(HealthInsuranceStatus)),
  legal_form: yup
    .string()
    .nullable()
    .oneOf(Object.values(LegalForm), 'field.invalidValue'),
  pension_insurance_status: yup
    .string()
    .nullable()
    .oneOf(Object.values(PensionInsuranceStatus)),
  salary_group: yup
    .string()
    .nullable()
    .oneOf(Object.values(SalaryGroup), 'field.invalidValue'),
  experience_level: yup
    .string()
    .nullable()
    .oneOf(Object.values(ExperienceLevel), 'field.invalidValue'),
  gross_salary: yup.number().nullable(),
  net_salary: yup.number().nullable(),
  employee_count: yup.number().nullable(),
  child_benefit_eligible_children: yup.number().nullable(),
  child_benefit_non_eligible_children: yup.number().nullable(),
  is_smoker: yup.boolean().nullable(),
  children: yup
    .array()
    .nullable()
    .of(
      yup.object().shape({
        name: yup.string().required('field.required'),
        year_of_birth: yup
          .string()
          .max(4, 'field.invalidValue')
          .min(4, 'field.invalidValue'),
      }),
    ),
});

export const updateCustomerValidationSchema = yup.object().shape({
  customer: newCustomerValidationSchema,
  partner: partnerValidationSchema.nullable(),
});
