import { FC, useMemo } from 'react';
import RadioSelect, { RadioSelectProps } from '@/components/ui/RadioSelect';
import useFormField from '@/hooks/useFormField';

export interface RadioSelectFieldProps
  extends Omit<RadioSelectProps, 'error' | 'value' | 'onSelect'> {
  fieldName: string;
  valueTransformer?: (value: string | boolean | number) => string | undefined;
  selectTransformer?: (value: string) => string | number | boolean;
}

const RadioSelectField: FC<RadioSelectFieldProps> = ({
  fieldName,
  valueTransformer,
  selectTransformer,
  ...props
}) => {
  const { value, error, setValue } = useFormField<string | boolean | number>(
    fieldName,
    '',
  );

  const transformedValue = useMemo(() => {
    return valueTransformer ? valueTransformer(value) : value.toString();
  }, [value, valueTransformer]);

  const handleSelect = (nextValue: string) => {
    setValue(selectTransformer ? selectTransformer(nextValue) : nextValue);
  };

  return (
    <RadioSelect
      value={transformedValue || ''}
      error={error}
      onSelect={handleSelect}
      {...props}
    />
  );
};

export default RadioSelectField;
