import { SXStyleSheet } from '@/utils';

const style = SXStyleSheet.create({
  container: (theme) => ({
    width: '82px',
    height: '100%',
    justifyContent: 'space-between',
    borderRight: `1px solid ${theme.palette.divider}`,
    background: `${theme.palette.primary.dark}`,
    alignItems: 'flex-start',
    px: 4,
    py: 8,
  }),
  altColorContainer: (theme) => ({
    background: `${theme.palette.grey.A700}`,
  }),
  image: {
    width: '100%',
  },
  itemsContainer: {
    width: '100%',
    flex: 1,
  },
  noLogo: {
    pt: 7,
  },
});

export default style;
