import { TableCell, TableRow as MUITableRow } from '@mui/material';
import { FC } from 'react';

import style from './style';
import { Customer } from '@/types';
import { join, map } from 'lodash';
import { TableItemConfig } from '@/components/ui/TableHeader';

export interface CustomerTableRawProps {
  data: Customer;
  tableConfig: TableItemConfig<Customer>[];
  onCustomerClick?: (id: number) => void;
}

const CustomerTableRaw: FC<CustomerTableRawProps> = ({
  data,
  tableConfig,
  onCustomerClick,
}) => {
  const handleCustomerClick = () => {
    onCustomerClick?.(data.id);
  };

  const renderCell = ({ key, transformValue }: TableItemConfig<Customer>) => {
    if (!key) {
      return;
    }

    if (Array.isArray(key)) {
      const value = join(
        map(key, (item) => data[item]),
        ' ',
      );
      return <TableCell>{value}</TableCell>;
    }

    return (
      <TableCell>
        {transformValue ? transformValue(data[key]) : data[key]?.toString()}
      </TableCell>
    );
  };

  return (
    <MUITableRow sx={style.row} onClick={handleCustomerClick}>
      {map(tableConfig, renderCell)}
    </MUITableRow>
  );
};

export default CustomerTableRaw;
