import { Stack, TableCell, TableHead, TableRow } from '@mui/material';
import { map } from 'lodash';
import { useTranslation } from 'react-i18next';

import { IconType } from '@/assets/icons';
import style from './style';
import { getTranslationKey } from '@/utils/system';
import { SXStyleSheet } from '@/utils';
import Icon from '@/components/ui/Icon';

export interface TableItemConfig<T = object> {
  label: string;
  key?: keyof T | (keyof T)[];
  width?: number;
  sortKey?: string;
  sortIcon?: IconType;
  transformValue?: (value: T[keyof T]) => string | null;
}

export interface TableHeaderProps<T extends object> {
  trKey: string;
  items: TableItemConfig<T>[];
}

const TableHeader = <T extends object>({
  trKey,
  items,
}: TableHeaderProps<T>) => {
  const { t } = useTranslation();

  const renderTableCell = (item: TableItemConfig, i: number) => {
    return (
      <TableCell
        sx={SXStyleSheet.merge(
          style.cell,
          !!item.width && {
            width: item.width,
            minWidth: item.width,
          },
        )}
        key={i}
      >
        <Stack alignItems="center" direction="row" gap={1}>
          {t(
            getTranslationKey(
              `${trKey}.${item.label}`,
              'errors:custom.noTranslation',
            ),
          )}
          {!!item.sortIcon && <Icon icon={item.sortIcon} sx={style.icon} />}
        </Stack>
      </TableCell>
    );
  };

  return (
    <TableHead sx={style.container}>
      <TableRow>{map(items, renderTableCell)}</TableRow>
    </TableHead>
  );
};

export default TableHeader;
