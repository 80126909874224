import { Stack, StackProps } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

import style from './style';
import { SXStyleSheet } from '@/utils';

const FormCard: FC<PropsWithChildren<StackProps>> = ({
  children,
  sx,
  ...props
}) => {
  return (
    <Stack
      sx={SXStyleSheet.merge(style.container, sx)}
      p={6}
      pb={10}
      gap={6}
      {...props}
    >
      {children}
    </Stack>
  );
};

export default FormCard;
