import { SXStyleSheet } from '@/utils';

const style = SXStyleSheet.create({
  container: {
    height: '92px',
    borderTop: '1px solid',
    borderTopColor: 'divider',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '78px',
    px: 8,
  },
});

export default style;
