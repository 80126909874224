import { SXStyleSheet } from '@/utils';

const style = SXStyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  navContainer: {
    flexDirection: 'row',
    gap: 3,
  },
  iconBack: {
    rotate: '180deg',
  },
});

export default style;
