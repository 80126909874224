import { Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import TextField from '@/components/formControl/TextField';
import DateField from '@/components/formControl/DateField';
import TextButton from '@/components/ui/TextButton';

import { Child } from '@/types';
import moment from 'moment';
import { SXStyleSheet } from '@/utils';

export const childInitialValues: Child = {
  name: '',
  year_of_birth: '',
};

export interface ChildFormProps {
  formKey: string;
  index: number;
  onDelete: (index: number) => void;
}

const ChildForm: FC<ChildFormProps> = ({ index, formKey, onDelete }) => {
  const { t } = useTranslation();

  const handleDelete = () => {
    onDelete(index);
  };

  return (
    <Stack gap={8}>
      <Stack sx={style.headerContainer}>
        <Typography variant="subtitle2">
          {t('customer:newCustomer.child', { count: index + 1 })}
        </Typography>
        <TextButton
          icon="Delete"
          text={t('customer:newCustomer.delete')}
          color="error"
          onClick={handleDelete}
        />
      </Stack>
      <Stack flexDirection="row" alignItems="center" gap={8} px={6}>
        <TextField
          inline
          hideErrorMessage
          labelFixedWidth="150px"
          fieldName={`${formKey}.${index}.name`}
          label={t('customer:newCustomer.form.firstName.label')}
        />
        <DateField
          labelFixedWidth="150px"
          fieldName={`${formKey}.${index}.year_of_birth`}
          label={t('customer:newCustomer.form.dateOfBirth.label')}
          format="YYYY"
          saveFormat="YYYY"
          views={['year']}
          max={moment()}
        />
      </Stack>
    </Stack>
  );
};

const style = SXStyleSheet.create({
  headerContainer: {
    borderBottom: '1px solid',
    borderBottomColor: 'divider',
    flexDirection: 'row',
    p: 6,
    gap: 6,
    alignItems: 'center',
  },
});

export default ChildForm;
